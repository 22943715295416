import React, { useMemo } from 'react'
import Image, { ImageProps, StaticImageData } from 'next/image'

export interface BaseImageProps extends Omit<ImageProps, 'src'> {
  src: string | StaticImageData | null | undefined
  defaultImageSrc: string
}

const BaseImage = ({ defaultImageSrc, src, alt, ...props }: BaseImageProps) => {
  const [useLocalImage, setUseLocalImage] = React.useState(false)

  const localSrc = useMemo(() => {
    if (!src) {
      setUseLocalImage(true)
      return defaultImageSrc
    }
    if (typeof src === 'string') {
      return src.startsWith('//') ? `https:${src}` : src
    }

    return src
  }, [src])
  const [source, setSource] = React.useState(localSrc)

  const onError = () => {
    setSource(defaultImageSrc)
    setUseLocalImage(true)
  }

  return (
    <Image
      {...props}
      alt={alt || ''}
      src={source}
      onError={onError}
      // We use our own custom formatting and CDN (aws) so do not need to pay for Vercel image optimization.
      // NB! When unoptimized=true, src is required to start with https://
      // Images fetched from project e.g Default images, must be unoptimized=false, or they won't be fetched correctly
      unoptimized={!useLocalImage}
    />
  )
}

export default BaseImage
