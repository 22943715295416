import React, { memo } from 'react'
import BaseImage, { BaseImageProps } from '@components/BaseImage'

const DEFAULT_IMAGE = '/img/default-cover.png'

const BookImage = memo((props: Omit<BaseImageProps, 'defaultImageSrc'>) => (
  <BaseImage {...props} defaultImageSrc={DEFAULT_IMAGE} />
))

export default BookImage
